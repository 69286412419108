// Config values
$platform-name: 'monkamicentreinc';
$assets-path: "../assets/";

// Font import
@font-face {
    font-family: 'butler-regular';
    src: url($assets-path + 'butler-regular.woff2') format('woff2'),
         url($assets-path + 'butler-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


// Colours
$copper: #F5A68A;
$stone: #CBD1D6;
$charcoal: #333334;

$brand-primary: $copper;
$brand-secondary: $copper;
$donate-colour: $copper;

$black: #000;
$grey-dark: $charcoal;
$grey: #626363;
$grey-light: #F0F0F0;
$white: #FFF;


// Layout
$container-max-width: 1230px;
$gap-width: 20px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey;
$box-shadow: 0px 0px 0px 1px rgba($charcoal, 1);


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);


// Spacers
$spacer: .75rem;
$spacer-y: $spacer;
$spacer-x: $spacer;


// Misc
$body-bg: $white;
$body-colour: $charcoal;
$profile-pics-enabled: false;
$border-width: 0px;
$date-ordinals-enabled: true;


// Transitions
$transition-duration: 0.2s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;


// Logo
$logo: "logo.svg";
$logo-width: 340px;
$logo-height: 100px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.6;
$logo-mobile-height: $logo-height * 0.6;


// Typography
$font-family-base: 'Work Sans', sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: 'butler-regular', serif;
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.2;
$headings-colour: $body-colour;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;


// Links
$link-colour: $black;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;


// Icon font
$icon-font-family: "FontAwesome";


// Buttons
$btn-background-colour: $white;
$btn-text-colour: text-contrast($btn-background-colour) !default;
$btn-border-radius: $border-radius;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.65rem;
$btn-padding-y: .625rem;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-base;
$btn-text-transform: none;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;


// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour) !default;


// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: text-contrast($body-bg);
$social-icons-hover-colour: lighten($social-icons-colour, 10%);
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.4rem;
$social-icons-gap: 1.5px;
$social-icons-border-radius: $btn-border-radius;
$social-icons-box-shadow: none;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $white;
$social-icons-header-hover-colour: $stone;

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $stone;


// Share this page
$share-enabled: true;
$share-text-align: center;
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h4;
$share-label-font-colour: $body-colour;


// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: $body-colour;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $body-colour;


// Field groups
$field-group-label-width: 280px;


// Tables
$table-cell-padding: 0.75rem;


// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: 0px solid $border-colour;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer;
$card-details-background-colour: $white;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $link-colour;
$card-heading-font-size: $font-size-h3;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $card-details-background-colour;
$card-footer-colour: $body-colour;
$card-min-width: 280px;

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom; // top, center, bottom
$card-text-overlay-details-position-x: left; // left, center, right

// Card text over
$card-text-over-image-vertical-text-position: center; // top, center or bottom
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-vertical-text-position: top; // top, center or bottom
$card-side-by-side-breakpoint: map-get(
  $breakpoints,
  sm
); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 0.4;
$card-hover-image-scale: 1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-heading-colour: $copper;
$card-hover-summary-colour: text-contrast(
  $card-hover-details-background-colour
);

// Event cards
$event-card-floating-date: false;
// $event-card-floating-date-background-colour: $card-details-background-colour;
// $event-card-floating-date-colour: text-contrast(
//   $event-card-floating-date-background-colour
// );
// $event-card-floating-date-border-radius: $border-radius;
// $event-card-floating-date-margin-y: 5px;
// $event-card-floating-date-margin-x: 5px;


// Menu admin
$menu-admin-enabled: true;
$menu-admin-max-width: 100%;
$menu-admin-background-colour: $copper;
$menu-admin-contents-max-width: $container-max-width;
$menu-admin-font-size: $font-size-small;
$menu-admin-align-items: right;
$menu-admin-link-colour: text-contrast($stone);
$menu-admin-absolute: false;
$menu-admin-login-link-enabled: true;
$menu-admin-manager-link-enabled: true;
$menu-admin-my-details-link-enabled: true;
$menu-admin-logout-link-enabled: true;
$menu-admin-to-nav-breakpoint: map-get($breakpoints, sm);

// Header
$header-background-colour: $charcoal;
$header-box-shadow: none;
$header-absolute: false;


// Header content
$header-content-max-width: $container-max-width;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: $spacer;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;


// Tagline
$tagline-enabled: true;
$tagline-font-family: $font-family-base;
$tagline-font-weight: $font-weight-bold;
$tagline-font-size: $font-size-h5;
$tagline-colour: $stone;
$tagline-above-cta-breakpoint: 0;
$tagline-breakpoint: map-get($breakpoints, md);


// Main call to action
$cta-btn-background-colour: $white;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 10px;
$cta-padding-right-at-nav-breakpoint: 6.5em;
$cta-btn-breakpoint: map-get($breakpoints, md);
$cta-donate-on-appeal: false;


// Search
$header-search-enabled: false;


// Social icons
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0px;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, sm);


// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-box-shadow: none;
$header-sticky-logo-width: $logo-width * 0.6;
$header-sticky-logo-height: $logo-height * 0.6;
$header-sticky-menu-admin-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: $header-content-margin-top;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom;
$header-sticky-nav-margin-bottom: 0;


// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: 1100px;
$nav-background-colour: $charcoal;
$nav-text-transform: none;


// Top level items
$nav-top-level-item-padding: 12.5px 17.5px;
$nav-top-level-item-colour: $white;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: 1rem;
$nav-top-level-item-font-weight: $font-weight-normal;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $copper;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
// $nav-top-level-chevrons-colour: $nav-top-level-item-colour !default;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $charcoal;
// $nav-submenu-box-shadow: $box-shadow;
$nav-submenu-box-shadow: none;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $white;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-normal;
$nav-submenu-item-hover-colour: $copper;
$nav-submenu-item-hover-background-colour: rgba($white, 0.05);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $white;


// Burger button
$burger-btn-background-colour: $white;
$burger-btn-text-colour: $body-colour;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: null;


// Nav normal
$nav-normal-max-width: 100%;
$nav-normal-margin-top: 0;
$nav-normal-margin-bottom: 0;
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: center;
$nav-normal-mobile-background-colour: $charcoal;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour);


// Carousel
$carousel-max-width: 100%;
$carousel-margin-top: 0px;
$carousel-margin-bottom: 0px;
$carousel-image-overlay: transparent;
$carousel-contents-max-width: $carousel-max-width;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: below;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-background-colour: $white;
$carousel-details-box-shadow: none;
$carousel-details-max-width: $container-max-width;
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer*3 $site-bleed;
$carousel-details-margin-y: 0px;
$carousel-details-margin-x: 0px;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: $link-colour;
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-enabled: true;
$carousel-summary-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $btn-background-colour;
$carousel-read-more-border-colour: $btn-border-colour;
$carousel-read-more-colour: $btn-text-colour;
$carousel-read-more-font-size: $carousel-summary-font-size;


// Carousel controls
$carousel-controls-enabled: true;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 5px;
$carousel-controls-button-background-colour: transparent;
$carousel-controls-button-box-shadow: none;
$carousel-controls-position-y: 'bottom';
$carousel-controls-position-x: 'right';
$carousel-controls-margin-y: 0px;
$carousel-controls-margin-x: 20px;
$carousel-controls-icon-colour: $body-colour;
$carousel-controls-icon-font-size: 1.2em;
$carousel-controls-next-icon: "\f054";
$carousel-controls-prev-icon: "\f053";


// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;
$carousel-buttons-type: dots;


// Home Intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $stone;
$home-intro-box-shadow: none;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 10;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-font-family: $headings-font-family;
$home-intro-font-size: $font-size-h2;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: 1.35;
$home-intro-colour: text-contrast($home-intro-background-colour);


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer * 5;
$home-features-padding-bottom: $spacer * 5;
$home-features-cards-entry-animation-prefab: 1;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 3rem;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 5;
$impact-stats-background-colour: $copper;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints,m);
$impact-stats-overall-container-direction: column;
$impact-stats-direction: row;
$impact-stats-mobile-text-align: $impact-stats-text-align;


// Impact stats - Heading
$impact-stats-heading-enabled: true;
$impact-stats-heading-font-size: $font-size-h1;
$impact-stats-heading-text-align: $impact-stats-text-align;
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 5;


// Impact stats - Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column;
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width * 2;
$impact-stat-border-width: 0;


// Impact stats - Figure
$impact-stats-figure-colour: $body-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 5rem;


// Impact stats - Summary
$impact-stats-summary-colour: inherit;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-margin-top: 10px;


// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: $spacer * 7;
$home-feeds-padding-bottom: $spacer * 5;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;


// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h1;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: center;


// Feed item
$feed-item-read-more-enabled: false;


// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;


// Twitter feed
$home-feed-twitter-enabled: false;


// Footer
$footer-boxes-count: 3;
$footer-prefab: 7;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 5;
$footer-background-colour: $charcoal;
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: $white;
$footer-text-align: left;


// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;


// Newsletter
$newsletter-background-colour: $copper;
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h1;
$newsletter-heading-text-align: center;
$newsletter-heading-margin-bottom: $spacer * 5;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-button-height: 36px;
$newsletter-button-background-colour: transparent;
$newsletter-button-colour: text-contrast($newsletter-background-colour);
$newsletter-button-border-colour: text-contrast($newsletter-background-colour);
$newsletter-use-placeholders: true;


// Newsletter - Full width
$newsletter-hero-padding-y: $spacer * 6.5;
$newsletter-hero-contents-max-width: $footer-contents-max-width;


// Context container
$context-container-background-colour: $white;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;


// Posts
$post-content-max-width: $container-max-width;


// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 5;
$header-text-margin-bottom: $spacer * 3;

//
// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: 0;
$page-title-margin-bottom: 1rem;


// Sidebar
$sidebar-enabled: false;
$sidebar-layout: below;
$sidebar-adjacent-width: 300px;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;


// Associated lists
// $associated-latest-enabled: true !default;
// $associated-related-enabled: true !default;
// $associated-most-read-enabled: true !default;
// $associated-tag-cloud-enabled: false !default;
// $associated-max-number-of-posts: 4 !default;
// $associated-list-heading-font-size: $font-size-h4 !default;
// $associated-item-image-enabled: false !default;
// $associated-item-heading-font-size: $font-size-base !default;
// $associated-item-summary-enabled: false !default;
// $associated-item-border: true !default;


// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;


// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: false;
$listed-appeal-totaliser-enabled: true;


// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: left;


// Blockquote
$blockquote-background-colour: $copper;
$blockquote-margin-y: $spacer * 3;
$blockquote-padding: $spacer * 3;
$blockquote-text-align: center;
$blockquote-font-size: 1.5em;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);


// Fundraising
$fundraising-enabled: true ;


// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;


// Top fundraisers
$top-fundraisers-enabled: false;
$top-fundraisers-profile-pics-enabled: false;


// Donation form
$donation-form-layout: columns;
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: false;


// Donation amounts
$donation-amount-min-width: $card-min-width;
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;


// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: #F0F0F0;
$quick-giving-padding: $context-container-padding;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $copper;
$quick-giving-donation-amount-figure-colour: text-contrast($donate-colour);
$quick-giving-donation-amount-border-width: 1px;
$quick-giving-donation-amount-border-colour: $donate-colour;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour);


// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: overlay;
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: $container-max-width;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 7;
$home-quick-giving-background-colour: $charcoal;
$home-quick-giving-background-image: null;
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: $body-colour;
$home-quick-giving-donation-amount-border-width: 1px;
$home-quick-giving-donation-amount-border-colour: $white;
$home-quick-giving-donation-amount-selected-background-colour: $stone;
$home-quick-giving-donation-amount-selected-figure-colour: $body-colour;
$home-quick-giving-donate-btn-background-colour: $copper;
$home-quick-giving-donate-btn-colour: $body-colour;
$home-quick-giving-donate-btn-border-colour: $btn-border-colour;
$home-quick-giving-colour: $white;
$home-quick-giving-heading-colour: $white;
$home-quick-giving-heading-font-size: $font-size-h1;


// Overlay homepage quick giving
$home-quick-giving-type-overlay-items-side: left;
$home-quick-giving-type-overlay-gradient: linear-gradient(to right, rgba($black, .75) 0%, rgba($black, 0) 75% );


// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;


// Comments
$comment-add-image-enabled: false;
$comment-add-video-enabled: false;


// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: true;


// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $copper;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $btn-text-colour;


// Shop
$shop-enabled: true;
$basket-link-only-on-shop-pages: false;

// Departments list
$department-list-width: $sidebar-adjacent-width;
$department-list-background-colour: $context-container-background-colour;
$department-list-padding: $context-container-padding;
$department-list-border-radius: $context-container-border-radius;


// Product-price
$product-price-colour: $brand-secondary;
$product-price-font-size: $font-size-h4;


// Product post
$product-post-content-max-width: $post-content-max-width;


// Cookie consent
$cookie-consent-position: bottom;
$cookie-consent-background-colour: $charcoal;
$cookie-consent-colour: $white;
$cookie-consent-btn-background-colour: $copper;
$cookie-consent-btn-text-colour: $body-colour;
